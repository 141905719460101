import Head from "next/head";
import dynamic from "next/dynamic";
import { Suspense } from "react";

import NewsArticleSchemaScript from "./NewsArticleSchemaScript";

const EditableArea = dynamic(
  () => import("../../../components/MgnlEditor/EditableArea")
);

interface MagnoliaTemplatePageProps extends Wif.Mgnl.Page {
  children?: React.ReactNode;
}

export function Page({
  children,
  ...props
}: MagnoliaTemplatePageProps): JSX.Element | null {
  return (
    <>
      <Head>
        <NewsArticleSchemaScript pageContent={props} />
      </Head>

      {children ? (
        <div id="page">{children}</div>
      ) : props.main ? (
        <Suspense>
          <EditableArea content={props.main} className="EditableArea" />
        </Suspense>
      ) : null}
    </>
  );
}
