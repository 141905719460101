/* istanbul ignore file */

import { __APP_HOST_PUBLIC__ } from "../../../constants/app";
import { MagnoliaTemplate } from "../../../magnolia.config";
import { formatAssetLink } from "../../../util/magnolia";

export default function NewsArticleSchemaScript({
  pageContent,
}: {
  pageContent: Wif.Mgnl.ArticlePage;
}): JSX.Element | null {
  if (pageContent["mgnl:template"] !== MagnoliaTemplate.PageArticle) {
    return null;
  }

  let assets: string[] = [];

  pageContent.main?.["@nodes"].forEach((nodeName: string) => {
    const componentNode: Wif.Mgnl.Node = pageContent.main[nodeName];

    if (componentNode["mgnl:template"] === MagnoliaTemplate.ArticlePageHero) {
      const isImagePublished = typeof componentNode["asset"].image === "object";
      if (isImagePublished) {
        assets.push(formatAssetLink(componentNode["asset"].image["@link"]));
      }
    }
    if (
      componentNode["mgnl:template"] === MagnoliaTemplate.ArticleImageGallery
    ) {
      componentNode["images"]["@nodes"].forEach(
        (multiFieldNodeName: string) => {
          const imageNode: Wif.Mgnl.Image =
            componentNode["images"][multiFieldNodeName]["image"];
          const isImagePublished = typeof imageNode === "object";

          if (isImagePublished) {
            assets.push(formatAssetLink(imageNode["@link"]));
          }
        }
      );
    }
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          headline: pageContent.title,
          image: [assets],
          description: pageContent.metaDescription,
          datePublished: pageContent.publicationDate,
          publisher: {
            "@type": "Organization",
            name: "Business Finland",
            logo: __APP_HOST_PUBLIC__ + "/logo.png",
          },
        }),
      }}
    />
  );
}
